exports.components = {
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-prismic-blog-uid-tsx": () => import("./../../../src/pages/blog/{prismicBlog.uid}.tsx" /* webpackChunkName: "component---src-pages-blog-prismic-blog-uid-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("./../../../src/pages/disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-forklifts-tsx": () => import("./../../../src/pages/forklifts.tsx" /* webpackChunkName: "component---src-pages-forklifts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leadacid-tsx": () => import("./../../../src/pages/leadacid.tsx" /* webpackChunkName: "component---src-pages-leadacid-tsx" */),
  "component---src-pages-lithiumbattery-tsx": () => import("./../../../src/pages/lithiumbattery.tsx" /* webpackChunkName: "component---src-pages-lithiumbattery-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */)
}

